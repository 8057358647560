<template>
    <v-container class="fade">
        <v-row class="primary-font mb-10 mt-3">
            <v-col cols="12" md="6" class="py-3 f14 d-flex flex-column justify-center">
                <span class="f20 fw600">Our Solutions</span>
                <v-divider class="l-primary my-5" width="70%" />
                <p>
                    The primary types of services CSI provides include seminars and workshops, consulting and technical assistance services and customized learning solutions. To support and sustain these services, it will also produce and conduct researches or white papers on civil service, HR/OD and Best HR Practices in the public sector. It will also host learning events such as summits, conventions or congress for HR professionals and leaders in the public sector and accredit or certify trainers and programs. Following is a brief description of each of CSI’s products and services –
                </p>          
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                <v-img :src="require('@/assets/images/landing/csi-solutions.png')" contain/>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-10 ma-1">
            <v-col cols="12" md="5" v-for="(item, i) in solutions" :key="i" :style="'border: thin solid rgba(0,0,0,.12);'" class="d-flex flex-row align-start pa-5 ma-2">
                <!-- <v-card outlined class="d-flex flex-row align-start pa-5 ma-3"> -->
                    <v-avatar class="primary-fade mr-2">
                        <v-icon 
                            tile
                            color="l-primary"
                            v-text="item.icon">
                        </v-icon>
                    </v-avatar>
                    <section class="primary-font">
                        <div class="l-primary--text f20 fw600">
                            {{ item.title }}
                        </div>
                        <div class="mt-5 f14">
                            {{ item.text }}
                        </div>
                    </section>
                <!-- </v-card> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.primary-fade {
    background-color: rgba(224, 88, 53, 0.1);
}
</style>

<script>
export default ({
    data: () => ({
        solutions: [
            {
                icon: 'mdi-school',
                title: 'Scholarships Management',
                text: 'CSI will continue to serve as a one-stop-shop for all the scholarship program needs of the bureaucracy including but not limited to communication, screening, tracking, evaluation and service improvements activities that promote continuous learning among recipients and return on learning investment for the bureaucracy. In the future, the program may expand into a form of cadetship to provide government agencies with a good supply of competent and dedicated civil servants.'
            },
            {
                icon: 'mdi-cogs',
                title: 'Consulting Services, Technical Assistance and Customized Solutions',
                text: 'CSI will specialize in holistic consulting services that build on partnership with requesting agencies to address their unique and high priority HR/OD needs. This includes development of transition and communications plans, success measures and pointers for sustainability.'
            },
            {
                icon: 'mdi-account-tie',
                title: 'Symposiums and Conventions for HR Professionals and Leaders',
                text: 'CSI will host alternative learning fora that promote sharing of best practices in Workplace Learning and Performance both in private and in public, partnership building among the talent stakeholders and action planning on strategic people development agenda across the bureaucracy.'
            },
            {
                icon: 'mdi-certificate',
                title: 'Program Accreditation and/or Trainer Certification',
                text: 'CSI will manage the learning service provider accreditation process and trainers’ pool certification programs for the public sector. This includes services in curriculum/program design review and customization, standards in LSP service levels, build up and categorization of LSPs, external learning brokering, negotiations on learning packages and fees, public sector trainers’ development plan and exposure to other government agencies, to name a few.'
            },
            {
                icon: 'mdi-note-edit-outline',
                title: 'Research, White Paper and Best Practices on Learning and Development',
                text: 'CSI will conduct, consolidate and publish evidence-based studies related to the application of Workplace Learning and Performance principles in the bureaucracy. CSI will also be the repository of action researches implemented in the public sector as a requirement of the CSC scholarship program to encourage sharing of best practices in talent development.'
            },
        ]
    })
})
</script>
